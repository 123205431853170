(function($) {
  $(function() {
    $("a").on("click", function(e) {
      var href = $(this).attr("href");

			if (href.indexOf("#") == -1) return;

      var id = "#" + href.split("#")[1];

      if ($(id).length > 0 && !$(id).hasClass("carousel") && !$(this)[0].hasAttribute("data-toggle")) {
				//e.preventDefault();

        if ($(id).is(":visible")) {
          $("html, body").animate(
            {
              scrollTop: $(id).offset().top
            },
            400
					);
        }
      }
    });
  });
})(jQuery);
