(function($) {
  $(function() {
    $(window).on("scroll", function() {
      var top = window.pageYOffset || document.documentElement.scrollTop;
      var scrollTopElement = $(".scroll-up");

      //zobraz pouze pokud odskroluji vic nez vyska displaye
      if (top > $(window).height()) {
        if (!scrollTopElement.hasClass("isActive")) scrollTopElement.addClass("isActive");
      } else {
        if (scrollTopElement.hasClass("isActive")) scrollTopElement.removeClass("isActive");
      }
    });
  });
})(jQuery);
