$(function() {
  $(".modal").on("show.bs.modal", function(e) {
    //add classname to related button
    e.relatedTarget.classList.add("isActive");

    //add modal id to backdrop classname
    setTimeout(function() {
      var backdrop = $(".modal-backdrop");
      var currentModalId = e.relatedTarget.getAttribute("data-target").replace("#", "");
      backdrop.addClass("modal-backdrop__" + currentModalId);

      //hide another modal if opened
      var anotherVisibleModal = $(".modal.show");
      if (anotherVisibleModal.length > 0) {
        $("body").attr("data-padding", $("body").css("padding-right"));
        anotherVisibleModal.modal("hide");
      } else {
        $("body").removeAttr("data-padding");
      }
    }, 5);
  });

  //add classname to body if modal is opened
  $(".modal").on("hidden.bs.modal", function(e) {
    var anotherVisibleModal = $(".modal.show");
    if (anotherVisibleModal.length > 0) {
      $("body")
        .addClass("modal-open")
        .css("padding-right", $("body").attr("data-padding"));
    } else {
      $("body").removeAttr("data-padding");
    }
  });

  //remove active class after modal closed
  $(".modal").on("hide.bs.modal", function(e) {
    var currentModalId = e.target.getAttribute("id");
    $('[data-target="#' + currentModalId + '"]').removeClass("isActive");
  });

  $("body").on("click", "#submenu .items__col button", function() {
    console.log($(this).attr("aria-expanded"));
    if ($(this).attr("aria-expanded") === "true") {
      $(this).next().next().show();
    } else {
      $(this).next().next().hide();
    }
  }).on("click", ".tabs-row a", function () {
    $(".tabs-row a").removeClass("isActive");
    $(this).addClass("isActive");
    $(".tab-panel").hide();
    $(".tab-panel-" + $(this).data("tabpanel")).show();
  }).on("click", "button[data-target='#modal-search']", function () {
    $("input[name='search']").focus();
  }).on("click", ".js-show-download .down-arrow", function () {
    $(this).hide();
    $(this).prev().css("display", "inline-block");
    $(".attachment, .links").attr("style","display: flex !important");
  }).on("click", ".js-show-download .up-arrow", function () {
    $(this).hide();
    $(this).next().css("display", "inline-block");
    $(".attachment, .links").attr("style","display: none !important");
  });
});
